var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "app" } }, [
    _c(
      "div",
      { ref: "chatContainer", staticClass: "chat-container" },
      _vm._l(_vm.messages, function (msg, index) {
        return _c("message", { key: index, attrs: { item: msg } })
      }),
      1
    ),
    _c("div", { staticClass: "input-container" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.currentMessage,
            expression: "currentMessage",
          },
        ],
        attrs: {
          type: "text",
          readonly: _vm.wait ? true : undefined,
          placeholder: _vm.wait ? "正在思考..." : "请输入问题...",
        },
        domProps: { value: _vm.currentMessage },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.sendMessage($event)
          },
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.currentMessage = $event.target.value
          },
        },
      }),
      _c("button", { on: { click: _vm.sendMessage } }, [_vm._v("发送")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }