<template>
    <div class="message">
        <!-- 分两列显示内容和时间。 -->
        <div class="user-message">
          <span class="text">用户输入：{{ item.askMessage }}</span>
          <span class="time">{{ formatTime(item.createTime) }}</span>
        </div>
        <!-- 显示内容详情。 -->
        <!-- 在div里显示详细内容。 -->
        <div class="bot-message">
        <div v-if="item.answerResult && item.answerResult.content" v-html="item.answerResult.content">
        </div>
        <div v-else>
            正在思考中...
        </div>
        </div>
    </div>
</template>
<script>
// 引用momentjs
import moment from 'moment'
export default {
  name: 'Message',
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  methods:{
    // 格式化时间
    formatTime(time){
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>
<style lang="scss" scoped>
.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  display:inline-block;
  width:100%
}

.user-message{
    // flex布局，左右分布
    display: flex;
    justify-content: space-between;
    width:100%;
    // 设置box-sizing
    box-sizing: border-box;
}
// 时间字体小一点。
.time{
    font-size: 12px;
}

</style>