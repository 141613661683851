var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "message" }, [
    _c("div", { staticClass: "user-message" }, [
      _c("span", { staticClass: "text" }, [
        _vm._v("用户输入：" + _vm._s(_vm.item.askMessage)),
      ]),
      _c("span", { staticClass: "time" }, [
        _vm._v(_vm._s(_vm.formatTime(_vm.item.createTime))),
      ]),
    ]),
    _c("div", { staticClass: "bot-message" }, [
      _vm.item.answerResult && _vm.item.answerResult.content
        ? _c("div", {
            domProps: { innerHTML: _vm._s(_vm.item.answerResult.content) },
          })
        : _c("div", [_vm._v(" 正在思考中... ")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }