<!-- index.html -->
<template>
  <div id="app">
    <!-- 显示左右两栏左侧200像素宽度。 -->
    <!-- <div class="left"> -->
    <!-- </div> -->

    <!-- <div class="right"> -->
    <div ref="chatContainer" class="chat-container">
      <message v-for="(msg, index) in messages" :key="index" :item="msg"></message>
    </div>

    <div class="input-container">
      <input type="text" v-model="currentMessage" :readonly="wait?true:undefined" @keyup.enter="sendMessage" :placeholder="wait?'正在思考...':'请输入问题...'" />
      <button @click="sendMessage">发送</button>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
import message from "./components/message.vue"
import { chatList, SendQuestion, chatAnswer } from '@/api/system/ai'
import { getSessionStorageObj } from '@/utils/db'
export default {
  components: {
    message
  },
  data () {
    return {
      messages: [],
      //当前消息
      currentMessage: '',
      //当前消息id
      currentAskId: '',
      wait: false,
      //   30秒超时时间。
      timeout: 30
    }
  },
  mounted () {
    this.getChatList()
  },
  computed: {
    userInfo: function () {
      return getSessionStorageObj('users')
    }
  },
  methods: {
    // 轮询根据问题，ID每5秒调用一次getChartAnswer
    getChatAnswerInterval () {
      // 计数器
      let count = 0
      // 间隔时间
      let seconds = 5000;
      const intervalHandler = setInterval(() => {
        this.getChatAnswer(this.currentAskId).then(res => {
          //返回值res.data的isFinish，如果是true的话取消定时器

          //json 反序列化res.data.answerResult
          res.data.answerResult = JSON.parse(res.data.answerResult)
          //检查反序列化结果中的isFinish。
          if ((res.data.answerResult != null && res.data.answerResult.isFinish)
            || count * seconds > this.timeout * 1000) {
            clearInterval(intervalHandler)
          }

          if (res.code == 200) {
            console.log(res.data)
          }
        })
      }, seconds);
    },

    // 发送问题
    sendMessage () {
      if (this.currentMessage) {
        // wait=true 阻止用户继续输入。
        this.wait = true;

        // 清空输入框中的文字。
        let askMessage = this.currentMessage;
        this.currentMessage = '';
        SendQuestion({ askMessage }).then(res => {
          if (res.code == 200) {
            //控制台输出结果用于调试。
            console.log(res.data)
            this.wait = false;
            this.currentAskId = res.data
            // 添加到消息列表中。
            this.messages.push({
              askId: res.data,
              askMessage: askMessage,
              createTime: new Date(),
              answerResult: null
            })
            // 开启轮巡
            this.getChatAnswerInterval()
            // 滚动条移动到最底端。
            this.$nextTick(this.scrollChatToBottom)
          }
        })
        this.currentMessage = ''
      }
      // 使用element的提示，用户必须输入。
      else {
        this.$message({
          message: '请输入问题',
          type: 'warning'
        })
      }

    },

    // 根据用户id,获取会话记录
    getChatList () {
      chatList().then(res => {
        if (res.code == 200) {
          console.log(res)

          // 循环返回结果
          res.rows.forEach(item => {
            //根据item.askId 检查list中是否存在, 如果不存在就添加，如果存在就更新
            this.findAndUpdateOrPush(this.messages, item.askId, item)
          })
        }
      })
    },

    // 根据问题id,获取回答记录
    getChatAnswer (askId) {
      // 返回一个promise对象。
      return new Promise((resolve, reject) => {
        chatAnswer(askId).then(res => {
          if (res.code == 200) {
            console.log(res.data)
            //根据res.data.data.askId 检查list中是否存在, 如果不存在就添加，如果存在就更新
            this.findAndUpdateOrPush(this.messages, res.data.data.askId, res.data.data)
            resolve(res.data)
          }
        })
      })
    },
    findAndUpdateOrPush (messages, askId, answer) {
      const found = messages.find(x => x.askId === askId);
      if (found) {
        found.answerResult = JSON.parse(answer.answerResult);
      } else {
        answer.answerResult = JSON.parse(answer.answerResult);
        messages.push(answer);
      }

      // 滚动条移动到最底端。
      this.$nextTick(this.scrollChatToBottom)
    },
    // 滚动条移动到最底端。
    scrollChatToBottom () {
      // 获取滚动条对象。
      const chatContainer = this.$refs.chatContainer;
      // 滚动条移动到最底端。
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  

  }
}
</script>
<style>
/* 设置全局字体和颜色 */
html,
body {
  font-family: Arial, sans-serif;
  color: #fff;
  background-color: #1e1e1e;
}

/* 使用flex布局 */
#app {
  display: flex;
  flex-direction: column;

  /* 绝对布局，填充整个页面。 */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
}

/* 显示消息的容器 */
.chat-container {
  /* 禁止横向滚动条。 */
  overflow-x: hidden;
  overflow-y: auto; /* 添加滚动条 */

  flex-grow: 1; /* 填充剩余空间 */
  padding: 20px;
  margin-bottom: 50px; /* 防止输入框遮挡消息 */
  box-sizing: border-box; /* 防止padding撑大容器 */
}

/* 每个消息的样式 */
.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
}

/* 用户发送的消息 */
.user-message {
  color: #007acc;
  /* 设置行高。 */
  line-height: 1.5;
}

/* ChatGPT回复的消息 */
.bot-message {
  /* 背景色使用#282C34,设置内边距和圆角。 */
    background-color: #282C34;
    padding: 10px;
    border-radius: 5px;
    /* 增大上部距离。 */
    margin-top: 10px;
  /* 设置行高。 */
  line-height: 1.5;
}

/* 输入框容器 */
.input-container {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0px 10px;
  box-sizing: border-box;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 10px;
}

/* 输入框 */
.input-container input[type='text'] {
  flex-grow: 1;
  height: 100%;
  border: none;
  padding: 0px 10px;
  /* background-color: transparent; */
  /* 圆角边框六像素。 */
  border-radius: 6px;
  background-color: #252526;
  color: #fff;
  outline: none;
}

/* 发送按钮 */
.input-container button {
  margin-left: 10px;
  margin-right: 10px;
  padding: 5px 10px;
  border: none;
  background-color: #007acc;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

/* 左侧*/
.left {
  float: left;
  width: 200px;
  height: 100%;
  background-color: #323233;
  color: #fff;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
}
/* 右侧*/
.right {
  float: right;
  width: calc(100% - 200px);
  height: 100%;
  background-color: #1e1e1e;
  color: #fff;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
}

/* 美化滚动条。 */
/* 滚动条整体样式 */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* 滚动条背景色 */
::-webkit-scrollbar-track {
  background-color: transparent;
}
/* 滚动条滑块颜色 */
::-webkit-scrollbar-thumb {
  background-color: #e9e9e9;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #6d6d6d;
}
</style>